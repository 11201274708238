import { service } from './service';

import { config } from './config';

const { default_headers } = config;

const request = (option) => {
  const { url, method, params, data, headersType, responseType } = option;
  
  return service({
    url: url,
    method,
    params,
    data,
    responseType: responseType,
    headers: {
      'Content-Type': headersType || default_headers
    }
  });
};

export default {
  get: async (option) => {
    const res = await request({ method: 'GET', ...option });
    return (res.data !== undefined ? res.data : res.model);
  },
  post: async (option) => {
    const res = await request({ method: 'POST', ...option });
    return (res.data !== undefined ? res.data : res.model);
  },
  postOriginal: async (option) => {
    const res = await request({ method: 'POST', ...option });
    return res;
  },
  delete: async (option) => {
    const res = await request({ method: 'DELETE', ...option });
    return (res.data !== undefined ? res.data : res.model);
  },
  put: async (option) => {
    const res = await request({ method: 'PUT', ...option });
    return (res.data !== undefined ? res.data : res.model);
  },
  download: async (option) => {
    const res = await request({ method: 'GET', responseType: 'blob', ...option });
    return res;
  },
  upload: async (option) => {
    option.headersType = 'multipart/form-data';
    const res = await request({ method: 'POST', ...option });
    return res;
  }
};