<template>
  <div class="bg" :style="{ filter:'blur('+blur+')', backgroundImage: 'url(' + bgImages[bgImageIndex] + ')' }"></div>
</template>

<style scoped lang="scss">
.bg {
  background-color: #000;
  position: fixed;
  object-fit: cover;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 0;
  background-size: cover;
  background-repeat: no-repeat;
}

</style>
<script>
export default {
  props:['blur'],
  data() {
    // 初始化壁纸数组
    let bgNumber = process.env.VUE_APP_BG_NUM || 20;
    let bgImages = [];
    for(let i = 0; i <= bgNumber; i++) {
      bgImages.push(require(`@/asset/img/bg${i}.jpg`));
    }

    // 初始化壁纸序号
    let bgImageIndex = Number(localStorage.getItem('bgImageIndex')) || 0;

    return {
      bgImageIndex: bgImageIndex,
      bgImage: bgImages[bgImageIndex],
      bgImages: bgImages
    };
  },
  methods: {
    changeBgImage(newImageSeq) {
      if (isNaN(newImageSeq)|| newImageSeq < 0 || newImageSeq > this.bgImages.length - 1) {
        do {
          newImageSeq = Math.floor(Math.random() * this.bgImages.length);
        } while (newImageSeq === this.bgImageIndex);
      }
      this.bgImageIndex = newImageSeq; // 更新背景图片的索引
      localStorage.setItem('bgImageIndex', this.bgImageIndex.toString());
    }
  }
};
</script>