export  default {
    key: "system_setting",
    component: "SystemSetting",
    icon: "icon-setting",
    title: "系统偏好设置",
    iconColor: "#fff",
    iconBgColor: "#23282d",
    width: 800,
    height: 600,
    disableResize: true,
    hideInDesktop: true,
    keepInDock: true,
    menu: [
    {
        key: "setting",
        title: "系统偏好设置",
        sub: [
            {
                key: "close",
                title: "关闭",
            },
        ],
    },
    {
        key: "help",
        title: "帮助",
        sub: [
            {
                key: "send",
                title: "发送反馈",
            },
        ],
    },
],
}