export default{
    key: "demo_colorfull",
    component: "DemoColorFull",
    icon: "icon-changyongtubiao-mianxing-86",
    title: "花里胡哨",
    iconColor: "#fff",
    iconBgColor: "#ff4500",
    width: 420,
    height: 310,
    titleBgColor: "#ff4500",
    titleColor: "#fff",
}