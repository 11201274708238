export default {
    key: "system_finder",
    component: "SystemFinder",
    icon: "finder.png",
    title: "智 存",
    iconColor: "#fff",
    // iconBgColor: "#db5048",
    width: 1024,
    height: 680,
    keepInDock: true,
    hideInDesktop: true,
    innerLink: true,
    url: process.env.VUE_APP_DISK_BASE_URL,
    sendToken: true,
    // menu: [
    //     {
    //         key: "finder",
    //         title: "访达",
    //         sub: [
    //             {
    //                 key: "about",
    //                 title: "关于 访达",
    //             },
    //             {
    //                 isLine: true,
    //             },
    //             {
    //                 key: "setting",
    //                 title: "首选项",
    //             },
    //             {
    //                 isLine: true,
    //             },
    //             {
    //                 key: "close",
    //                 title: "退出 访达",
    //             },
    //         ],
    //     },
    //     {
    //         key: "window",
    //         title: "窗口",
    //         sub: [
    //             {
    //                 key: "min",
    //                 title: "最小化",
    //             },
    //             {
    //                 key: "max",
    //                 title: "最大化",
    //             },
    //         ],
    //     },
    //     {
    //         key: "help",
    //         title: "帮助",
    //         sub: [
    //             {
    //                 key: "send",
    //                 title: "发送反馈",
    //             },
    //         ],
    //     },
    // ]
}