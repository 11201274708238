<template>
  <div class="mac-os"
       @mousedown.self="boot"
       @contextmenu.prevent="onContextShow()">
    <transition name="fade">
      <Bg v-if="isBg" ref="refBg"></Bg>
    </transition>
    <transition name="fade">
      <Loading v-if="isLoading"
               @loaded="loaded"></Loading>
    </transition>
    <transition name="fade">
      <Login v-if="isLogin"
             @logined="logined"></Login>
    </transition>
    <transition name="fade">
      <DeskTop
        v-if="isDeskTop"
        ref="refDeskTop"
        @lockScreen="lockScreen"
        @shutdown="shutdown"
        @logout="logout"
        @launchpad="launchpad"
        @changeBgImage="changeBgImage"
      ></DeskTop>
    </transition>
    <transition name="fade">
      <LaunchPad v-if="isLaunchPad"></LaunchPad>
    </transition>
  </div>
</template>

<style scoped lang="scss">
.mac-os {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
}
</style>
<script setup>
import Bg from "@/components/Bg";
import LaunchPad from "@/components/LaunchPad";
import Loading from "@/components/Loading";
import Login from "@/components/Login";
import DeskTop from "@/components/DeskTop";

import {ref, onMounted, nextTick} from 'vue'
import * as authUtil from "@/utils/auth"
import * as LoginApi from "@/api/login/index"
let isBg = ref(true)
let isLoading = ref(false)
let isLogin = ref(false)
let isDeskTop = ref(false)
let isLaunchPad = ref(false)
let refBg = ref()
let refDeskTop = ref()
const openDefaultAppFlag = process.env.VUE_APP_OPEN_DEFAULT_APP === 'true'
// console.log('openDefaultApp===>', openDefaultAppFlag)

onMounted(() => {
  // boot();
  // 检查token有效性，决定是否回到登录页面还是保持当前页面
  try {
    const token = authUtil.getAccessToken()
    if (token && LoginApi.refreshToken(token)) {
      logined()
    } else {
      boot() // 否则回到登录页面
    }
  } catch (error) {
    console.error('检查token有效性错误:', error);
    boot(); // 出现错误时，回到登录页面
  }
})
const onContextShow = () => {
  console.log("onContextShow");
}
const boot = () => {
  isLoading.value = true;
}
const loaded = () => {
  isLoading.value = false;
  isBg.value = true;
  isLogin.value = true;
}
const logined = () => {
  isLogin.value = false;
  isDeskTop.value = true;

  // 使用 nextTick 确保 DeskTop 组件已经渲染
  nextTick(() => {
    if (openDefaultAppFlag && refDeskTop.value) {
      // console.log('open default App')
      refDeskTop.value.openDefaultApp();
    }
  });

}
const lockScreen = () => {
  isLogin.value = true;
}
const launchpad = (show) => {
  isLaunchPad.value = show;
}
const logout = () => {
  // TODO 删除所有与用户相关的信息，用authUtil来做
  // localStorage.removeItem("user_name");
  authUtil.removeUsername();
  isDeskTop.value = false;
  isLogin.value = true;
}
const shutdown = () => {
  //localStorage.removeItem("user_name");
  authUtil.removeUsername();
  isDeskTop.value = false;
  isLogin.value = false;
  isLoading.value = false;
  isBg.value = false;
}
const changeBgImage = (bgImageSeq) => {
  refBg.value.changeBgImage(bgImageSeq);
}
</script>
