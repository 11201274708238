export default {
    key: "aa2_admin",
    component: "SystemFinder",
    icon: "admin.png",
    title: "后台管理",
    iconColor: "#153594",
    iconBgColor: "#d0ddf4",
    //width: 1024,
    //height: 680,
    keepInDock: true,
    hideInDesktop: true,
    outLink: true,
    url: process.env.VUE_APP_ADMIN_BASE_URL
}