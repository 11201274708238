/**
 * 强制退出应用
 */
export default {
    key: "system_task",
    component: "SystemTask",
    icon: "icon-icon_roundclose_fill",
    title: "强制退出...",
    iconColor: "#fff",
    iconBgColor: "#333",
    width: 300,
    height: 400,
    disableResize: true,
    hideInDesktop: true,
    /*menu: [
        {
            key: "task",
            title: "TASK",
            sub: [
                {
                    key: "close",
                    title: "关闭",
                },
            ],
        },
        {
            key: "help",
            title: "帮助",
            sub: [
                {
                    key: "send",
                    title: "发送反馈",
                },
            ],
        },
    ],*/
}