import axios from 'axios';
import qs from 'qs';
import { config } from '@/config/axios/config';
import { getAccessToken, getRefreshToken, getTenantId, removeToken, setToken } from '@/utils/auth';
import { deleteUserCache } from '@/hooks/web/useCache';
import {ElMessage, ElMessageBox, ElNotification} from "element-plus";
import errorCode from './errorCode';

const tenantEnable = process.env.VUE_APP_TENANT_ENABLE;
const { result_code, base_url, request_timeout } = config;
// 需要忽略的提示。忽略后，自动 Promise.reject('error')
const ignoreMsgs = [
  //'无效的刷新令牌', // 刷新令牌被删除时，不用提示
  // '刷新令牌已过期' // 使用刷新令牌，刷新获取新的访问令牌时，结果因为过期失败，此时需要忽略。否则，会导致继续 401，无法跳转到登出界面
]
// 是否显示重新登录
export const isRelogin = { show: false }
// Axios 无感知刷新令牌，参考 https://www.dashingdog.cn/article/11 与 https://segmentfault.com/a/1190000020210980 实现
// 请求队列
let requestList = [];
let isRefreshToken = false;
const whiteList = ['/login', '/refresh-token'];

function isAbsoluteURL(url) {
    return url.startsWith('http://') || url.startsWith('https://');
}

export const service = axios.create({
  baseURL: base_url,
  timeout: request_timeout,
  withCredentials: false
});

service.interceptors.request.use(
  (config) => {
      // 完整url的话则不能加上baseURL了
      if (isAbsoluteURL(config.url)) {
          config.baseURL = '';
      }
    let isToken = (config.headers || {}).isToken === false;
    whiteList.some((v) => {
      if (config.url) {
        config.url.indexOf(v) > -1;
        return (isToken = false);
      }
    });
    if (getAccessToken() && !isToken) {
      config.headers.Authorization = 'Bearer ' + getAccessToken(); // 让每个请求携带自定义token
    }
    if (tenantEnable && tenantEnable === 'true') {
      const tenantId = getTenantId();
      if (tenantId) config.headers['tenant-id'] = tenantId;
    }
    const params = config.params || {};
    const data = config.data || false;
    if (
      config.method && config.method.toUpperCase() === 'POST' &&
      config.headers['Content-Type'] === 'application/x-www-form-urlencoded'
    ) {
      config.data = qs.stringify(data);
    }
    // get参数编码
    if (config.method && config.method.toUpperCase() === 'GET' && params) {
        config.params = {}
        const paramsStr = qs.stringify(params, { allowDots: true })
        if (paramsStr) {
            config.url = config.url + '?' + paramsStr
        }
    }
    return config;
  },
  (error) => {
    console.log(error);
    return Promise.reject(error);
  }
);

// response 拦截器
service.interceptors.response.use(
    async (response) => {
        let { data } = response
        const config = response.config
        if (!data) {
            throw new Error()
        }
        // const { t } = useI18n()
    // 未设置状态码则默认成功状态
    // 二进制数据则直接返回，例如说 Excel 导出
        if (
            response.request.responseType === 'blob' ||
            response.request.responseType === 'arraybuffer'
        ) {
            // 注意：如果导出的响应为 json，说明可能失败了，不直接返回进行下载
            if (response.data.type !== 'application/json') {
                return response.data
            }
            data = await new Response(response.data).json()
        }
        const code = data.code || result_code
        // 获取错误信息
        const msg = data.msg || errorCode[code] || errorCode['default']
        if (config.url && config.url.indexOf('/mask/') > -1) {
            if (Object.prototype.hasOwnProperty.call(data, 'success') && data.success === false) {
                ElMessage.error('MASK请求错误' + (data.errorMsg || '未知错误'))
                return Promise.reject(new Error(data.errorMsg || 'MASK请求错误'))
            }
        }

        if (ignoreMsgs.indexOf(msg) !== -1) {
            console.error('错误url=>', config.url)
            return Promise.reject(msg)
        } else if (code === 401) {
            // 如果未认证，并且未进行刷新令牌，说明可能是访问令牌过期了
            if (!isRefreshToken) {
                isRefreshToken = true
                // 1. 如果获取不到刷新令牌，则只能执行登出操作
                if (!getRefreshToken()) {
                    //return handleAuthorized()
                    return handleAuthorized().catch(error => {
                        return Promise.reject(error);
                    });
                }
                // 2. 进行刷新访问令牌
                try {
                    console.log('开始刷新令牌...')
                    const refreshTokenRes = await refreshToken()
                    // 2.1 刷新成功，则回放队列的请求 + 当前请求
                    setToken((await refreshTokenRes).data.data)
                    if (config.headers) {
                        config.headers.Authorization = 'Bearer ' + getAccessToken()
                    }
                    requestList.forEach((cb) => {
                        cb()
                    })
                    requestList = []
                    return service(config)
                } catch (e) {
                    // 为什么需要 catch 异常呢？刷新失败时，请求因为 Promise.reject 触发异常。
                    // 2.2 刷新失败，只回放队列的请求
                    requestList.forEach((cb) => {
                        cb()
                    })
                    // 提示是否要登出。即不回放当前请求！不然会形成递归
                    //return handleAuthorized()
                    return handleAuthorized().catch(error => {
                        return Promise.reject(error);
                    });
                } finally {
                    requestList = []
                    isRefreshToken = false
                }
            } else {
                // 添加到队列，等待刷新获取到新的令牌
                return new Promise((resolve) => {
                    requestList.push(() => {
                        if (config.headers) {
                            config.headers.Authorization = 'Bearer ' + getAccessToken()
                        }
                        resolve(service(config))
                    })
                })
            }
        } else if (code === 500) {
            ElMessage.error('服务器错误,请联系管理员!')
            return Promise.reject(new Error(msg))
        } else if (code === 901) {
            ElMessage.error({
                offset: 300,
                dangerouslyUseHTMLString: true,
                message: '<div>演示模式，无法进行写操作!</div>'
            })
            return Promise.reject(new Error(msg))
        } else if (code !== 200) {
            if (msg === '无效的刷新令牌') {
                // hard coding：忽略这个提示，直接登出
                console.log(msg)
                //return handleAuthorized()
                return handleAuthorized().catch(error => {
                    return Promise.reject(error);
                });
            } else {
                ElNotification.error({ title: msg })
            }
            return Promise.reject('error')
        } else {
            return data
        }
    },
    (error) => {
        console.log('err' + error) // for debug
        let { message } = error
        /*const { t } = useI18n()
        if (message === 'Network Error') {
            message = t('sys.api.errorMessage')
        } else if (message.includes('timeout')) {
            message = t('sys.api.apiTimeoutMessage')
        } else if (message.includes('Request failed with status code')) {
            message = t('sys.api.apiRequestFailed') + message.substr(message.length - 3)
        }*/
        ElMessage.error(message)
        return Promise.reject(error)
    }
)

const refreshToken = async () => {
    axios.defaults.headers.common['tenant-id'] = getTenantId()
    return await axios.post(base_url + '/system/auth/refresh-token?refreshToken=' + getRefreshToken())
}
export const handleAuthorized = () => {
    return new Promise((resolve, reject) => {
        if (!isRelogin.show) {
            // 如果已经到重新登录页面则不进行弹窗提示
            if (window.location.href.includes('login?redirect=')) {
                reject('请求超时');
                return;
            }
            isRelogin.show = true;
            ElMessageBox.confirm('请求超时', '请确认', {
                showCancelButton: false,
                closeOnClickModal: false,
                showClose: false,
                confirmButtonText: '重新登录',
                type: 'warning'
            }).then(() => {
                deleteUserCache(); // 删除用户缓存
                removeToken();
                isRelogin.show = false;
                // 干掉token后再走一次路由让它过router.beforeEach的校验
                // eslint-disable-next-line no-self-assign
                window.location.href = window.location.href;
                resolve();
            }).catch(() => {
                isRelogin.show = false;
                reject('请求超时');
            });
        } else {
            reject('请求超时');
        }
    });
}
