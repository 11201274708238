export default {
    key: "system_about",
    component: "SystemAbout",
    icon: "icon-question",
    title: "关于我们",
    iconColor: "#fff",
    iconBgColor: "#23282d",
    width: 400,
    height: 250,
    disableResize: true,
    hideInDesktop: true,
    menu: [
        {
            key: "about",
            title: "关于",
            sub: [
                {
                    key: "close",
                    title: "关闭",
                },
            ],
        },
        {
            key: "help",
            title: "帮助",
            sub: [
                {
                    key: "send",
                    title: "发送反馈",
                },
            ],
        },
    ]
}