export default {
    key: "system_store",
    component: "SystemStore",
    icon: "appstore.png",
    title: "应用商店",
    iconColor: "#fff",
    //iconBgColor: "#23282d",
    width: 800,
    height: 600,
    disableResize: true,
    hideInDesktop: true,
    keepInDock: true,
    // menu: [
    //     {
    //         key: "store",
    //         title: "应用商店",
    //         sub: [
    //             {
    //                 key: "about",
    //                 title: "关于 应用商店",
    //             },
    //             {
    //                 isLine: true,
    //             },
    //             {
    //                 key: "setting",
    //                 title: "首选项",
    //             },
    //             {
    //                 isLine: true,
    //             },
    //             {
    //                 key: "close",
    //                 title: "退出 应用商店",
    //             },
    //         ],
    //     },
    //     {
    //         key: "window",
    //         title: "窗口",
    //         sub: [
    //             {
    //                 key: "min",
    //                 title: "最小化",
    //             },
    //             {
    //                 key: "max",
    //                 title: "最大化",
    //             },
    //         ],
    //     },
    //     {
    //         key: "help",
    //         title: "帮助",
    //         sub: [
    //             {
    //                 key: "send",
    //                 title: "发送反馈",
    //             },
    //         ],
    //     },
    // ],
}