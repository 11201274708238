import request from '@/config/axios';
import { getRefreshToken } from '@/utils/auth';

// Login
export const login = (data) => {
  return request.post({ url: '/system/auth/login', data });
}

// Refresh access token
export const refreshToken = () => {
  return request.post({ url: '/system/auth/refresh-token?refreshToken=' + getRefreshToken() });
}

// Get tenant ID by tenant name
export const getTenantIdByName = (name) => {
  return request.get({ url: '/system/tenant/get-id-by-name?name=' + name });
}

// Logout
export const loginOut = () => {
  return request.post({ url: '/system/auth/logout' });
}

// aa2_server Logout API
export const loginOut2 = () => {
  // 使用 new URL() 来解析 VUE_APP_DISK_BASE_URL
  const urlObj = new URL(process.env.VUE_APP_DISK_BASE_URL);

  // 只获取 "scheme://hostname:port" 部分
  const rootUrl = process.env.VUE_APP_DISK_BASE_URL.slice(0, process.env.VUE_APP_DISK_BASE_URL.length - urlObj.pathname.length);
  const logoutUrl = `${rootUrl}/api/logout`;
  console.log(logoutUrl);
  return request.get({ url: `${logoutUrl}`, withCredentials: true });
}

// Get user permission info
export const getInfo = () => {
  return request.get({ url: '/system/auth/get-permission-info' });
}

// Routes
export const getAsyncRoutes = () => {
  return request.get({ url: '/system/auth/list-menus' });
}

// Social auth redirect
export const socialAuthRedirect = (type, redirectUri) => {
  return request.get({
    url: '/system/auth/social-auth-redirect?type=' + type + '&redirectUri=' + redirectUri
  });
}

// Get validation image and token
export const getCode = (data) => {
  return request.postOriginal({ url: 'system/captcha/get', data });
}

// Slide or click to check
export const reqCheck = (data) => {
  return request.postOriginal({ url: 'system/captcha/check', data });
}