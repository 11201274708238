import { JSEncrypt } from 'jsencrypt'
import { computed } from 'vue'
import {useCache} from '@/hooks/web/useCache'

const { wsCache } = useCache()

const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'

const publicKey = '...'
const privateKey = '...'

export const encrypt = (txt) => {
  const encryptor = new JSEncrypt()
  encryptor.setPublicKey(publicKey)
  return encryptor.encrypt(txt) 
}

export const decrypt = (txt) => {
  const encryptor = new JSEncrypt()
  encryptor.setPrivateKey(privateKey) 
  return encryptor.decrypt(txt) 
}

export function usePasswordField(item, passwordField = 'password') {
  return computed({
    get: () => {
      try {
        return item[passwordField] ? window.atob(item[passwordField]) : ''
      } catch {
        return item[passwordField] || ''
      }
    },
    set: (value) => (item[passwordField] = window.btoa(value))
  })
}

export function generateSM4Key(strKey) {
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789-=_+{}[]|:?.,<>;!*()^%$#@~`'

  if (!strKey) {
    strKey = Array.from(crypto.getRandomValues(new Uint8Array(16)))
      .map((item) => possible[item % possible.length])
      .join('')
  }

  let hexKey = ''
  for (let i = 0; i < strKey.length; i++) {
    hexKey += strKey.charCodeAt(i).toString(16)
  }
  return hexKey
}