export default {
    key: "aa2_finder-team",
    component: "SystemFinder",
    icon: "team2.png",
    title: "团队文件",
    iconColor: "#fff",
    iconBgColor: "#d0ddf4",
    //width: 1024,
    //height: 680,
    keepInDock: true,
    hideInDesktop: true,
    innerLink: true,
    url: process.env.VUE_APP_TEAM_BASE_URL,
    sendToken: true,
}