export  default {
    key: "system_launchpad",
    component: "SystemLaunchPad",
    icon: "launchpad.png",
    title: "启动台",
    iconColor: "#333",
    // iconBgColor: "#d4dbef",
    width: 500,
    height: 300,
    hideInDesktop: true,
    keepInDock: true,
}