import SystemAbout from "./system-about";
import SystemFinder from "./system-finder";
import SystemLaunchPad from "./system-launchpad";
import SystemSetting from "./system-setting";
import SystemStore from "./system-store";
import SystemTask from "./system-task";

import DemoDemo from "@/model/demo/demo-demo";
import DemoColorful from "@/model/demo/demo-colorful";

import Aa2Brower from "@/model/aa2/aa2-bing";
import Aa2FinderTeam from "@/model/aa2/aa2-finder-team";
import Aa2Search from "@/model/aa2/aa2-search";
import Aa2Chat from "@/model/aa2/aa2-chat";
import Aa2Admin from "@/model/aa2/aa2-admin";

// 所有可用的应用组件映射表
const allApps = {
  SystemAbout,
  SystemFinder,
  SystemLaunchPad,
  SystemSetting,
  SystemStore,
  SystemTask,
  DemoDemo,
  DemoColorful,
  Aa2Brower,
  Aa2FinderTeam,
  Aa2Search,
  Aa2Chat,
  Aa2Admin,
};

// 从环境变量中获取要加载的应用列表
const loadApps = process.env.VUE_APP_LOAD_APPS;

let appList;

if (loadApps) {
  appList = loadApps.split(',');
} else {
  console.warn('VUE_APP_LOAD_APPS is not defined in the .env file, loading all apps by default.');
  appList = Object.keys(allApps);
}

// 根据环境变量加载相应的应用组件
const allAppList = appList.map(appName => allApps[appName]).filter(app => app);

/**
 * 加载所有应用定义
 * 1. 外观上包括应用组件、图标、标题、颜色、长宽、标题栏颜色
 * 2. 参数上包括是否固定大小、是否从桌面隐藏、是否可以关闭、是否可以调整大小等
 * @param {string} key 指定一个APP的唯一值
 * @param {string} component 应用对应的组件地址
 * @param {string} icon 应用使用的图标
 * @param {string} title 应用标题
 * @param {string} iconColor 图标颜色
 * @param {string} iconBgColor 图标背景色
 * @param {number} width 应用宽度
 * @param {number} height 应用高度
 * @param {boolean} disableResize 是否固定大小
 * @param {boolean} hideInDesktop 是否从桌面隐藏
 * @param {boolean} outLink 是否外链
 * @param {boolean} innerLink 是否内链
 * @param {boolean} url 链接地址
 * @param {boolean} hideWhenClose 打开后只能隐藏无法彻底关闭
 * @param {boolean} titleBgColor 标题栏背景色
 * @param {boolean} titleColor 标题栏前景色
 * @param {boolean} multiTask 是否允许多任务方式打开
 *
 */
export default {
  allAppList,
};
