export default {
    key: "aa2_search",
    component: "Aa2Browser",
    icon: "search.png",
    title: "智 搜",
    iconColor: "#fff",
    // iconBgColor: "#71e4ee",
    //width: 1024,
    //height: 680,
    keepInDock: true,
    innerLink: true,
    url: process.env.VUE_APP_SEARCH_BASE_URL,
    hideInDesktop: true
}