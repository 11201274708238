<template>
  <div class="login">
    <div
        class="head"
        :style="{ backgroundImage: 'url(' + headImage + ')' }"
    ></div>
    <div class="message">
      {{ haveSavedUserName ? user_name : "欢迎使用 「大模型操作系统」" }}
    </div>
    <form class="form">
      <div
          class="item"
          v-if="!haveSavedUserName"
          :class="isUserNameError ? 'error' : ''"
      >
        <input
            class="account"
            placeholder="用户名"
            type="email"
            v-model="user_name"
            ref="refUserName"
            autocomplete="username"
        />
      </div>
      <div class="item" :class="isUserPasswordError ? 'error' : ''">
        <input
            class="password"
            placeholder="密码"
            type="password"
            v-model="user_password"
            ref="refPassword"
            :class="user_password ? 'password-in' : ''"
            @keyup.enter="login"
            autocomplete="current-password"
            show-password
        />
        <!-- 加载动画，显示于登录正在处理的阶段 -->
        <div v-if="loginLoading" class="loader"></div>
        <i
            v-else
            class="login-button iconfont icon-icon_send"
            :class="user_password ? 'click-enable' : ''"
            @click="login"
        ></i>
      </div>
    </form>
  </div>
</template>

<script name="Login" setup>
/* eslint-disable */
import {ref, onMounted, reactive} from 'vue'
import * as LoginApi from "@/api/login/index"
import * as authUtil from "@/utils/auth"
import { CACHE_KEY, useCache } from '@/hooks/web/useCache'
import {getInfo} from "@/api/login/index";

const emit = defineEmits({
  "logined": null
});

let headImage = ref(require("@/asset/img/bg0.jpg"));
let user_name = ref("");
let user_password = ref("");
let haveSavedUserName = ref(false);
let isUserNameError = ref(false);
let isUserPasswordError = ref(false);
let refUserName = ref(null);
let refPassword = ref(null);
const loginLoading = ref(false)

const loginData = reactive({
  isShowPassword: false,
  captchaEnable: process.env.VUE_APP_CAPTCHA_ENABLE || false,
  tenantEnable: process.env.VUE_APP_TENANT_ENABLE || true,
  loginForm: {
    tenantName: 'JDYOS',
    username: '',
    password: '',
    captchaVerification: '',
    rememberMe: false
  }
})

const { wsCache } = useCache()

onMounted(() => {
  let savedUserName = localStorage.getItem("user_name") || false;
  if (savedUserName) {
    user_name.value = savedUserName;
    haveSavedUserName.value = true;
  }

  if(!haveSavedUserName.value){
    refUserName.value.focus();
  } else {
    refPassword.value.focus();
  }

  //TODO 更换其它用户的组件和处理逻辑
});

const login = async () => {
  if (!user_name.value) {
    isUserNameError.value = true;
    setTimeout(() => {
      isUserNameError.value = false;
    }, 1000);
    return;
  }
  loginData.loginForm.username = user_name.value;

  if (!user_password.value) {
    isUserPasswordError.value = true;
    setTimeout(() => {
      isUserPasswordError.value = false;
    }, 1000);
    return;
  }
  loginData.loginForm.password = user_password.value;

  await handleLogin({});
  // tool.saveAccessToken("guest");
  // 通知父组件登录成功
  // emit("logined");
  // 将用户信息放入缓存
  //localStorage.setItem("user_name", user_name.value);
}

//获取租户ID
const getTenantId = async () => {
  if (loginData.tenantEnable === 'true') {
    const res = await LoginApi.getTenantIdByName(loginData.loginForm.tenantName)
    authUtil.setTenantId(res)
  }
}

//用户登录并获取用信息
const handleLogin = async (params) => {
  loginLoading.value = true
  let isLoginSuccess = false
  console.log('Desktop::handleLogin::Starting')

  try {
    await getTenantId()

    // console.log('Login API param::', loginData.loginForm)
    const res = await LoginApi.login(loginData.loginForm)
    // console.log('Login API response::', res)

    if (!res) {
      return
    } else if (res && res.accessToken) {
      isLoginSuccess = true
      // alert('登录成功，Token=' + res.accessToken)
    }

    if (loginData.loginForm.rememberMe) {
      authUtil.setLoginForm(loginData.loginForm)
    } else {
      authUtil.removeLoginForm()
    }
    authUtil.setToken(res)

    // 获取用户信息并存入cache中
    const userInfo = await getInfo()
    console.log('当前用户信息::', userInfo)
    wsCache.set(CACHE_KEY.USER, userInfo)
    authUtil.setUsername(userInfo.user.username)
    console.log('Desktop登录成功，用户名为::', authUtil.getUsername(), ', userInfo.user.username=', userInfo.user.username)


  } catch (error) {
    // 错误处理
    console.log('Handle login error:', error)
  } finally {
    /*
    setTimeout(() => {
      const loadingInstance = ElLoading.service()
      loadingInstance.close()
    }, 400)*/
    if (isLoginSuccess) {
      // push({ path: redirect.value || permissionStore.addRouters[0].path })
      // 通知父组件登录成功,跳转到桌面
      emit("logined")
    }
    loginLoading.value = false
  }
}
</script>
<style scoped lang="scss">
::-webkit-input-placeholder {
  color: #fff;
}

::-moz-placeholder {
  color: #fff;
}

:-ms-input-placeholder {
  color: #fff;
}
.login {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: white;
  margin-top: -100px;
  z-index: 99999;
  backdrop-filter: blur(100px);
  .head {
    background-size: 40% auto;
    background-position: center center;
    height: 150px;
    width: 150px;
    border-radius: 100%;
    box-shadow: 0px 0px 5px 5px rgba(0, 0, 0, 0.1);
    margin-top: -50px;
  }
  .message {
    margin-top: 20px;
    font-size: 20px;
    text-shadow: 0px 0px 2px 2px rgba(0, 0, 0, 0.3);
    color: #eee;
    margin-bottom: 50px;
  }
  .form {
    display: flex;
    flex-direction: column;
    align-items: center;

    .error {
      animation: loginErrorAnimation 0.2s ease 3;
    }
    .item {
      vertical-align: middle;
      position: relative;
      width: 250px;
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
      align-items: center;
      overflow: hidden;
      input {
        color: white;
        outline: none;
        border: none;
        margin: 5px;
        font-size: 16px;
        background-color: rgba(255, 255, 255, 0.3);
        padding: 8px 24px;
        border-radius: 20px;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
        width: 100%;
      }

      .password-in {
        width: 155px;
      }

      .password {
        transition: width 0.3s;
      }

      .login-button {
        position: absolute;
        top: 5px;
        right: -50px;
        transition: right 0.3s;
      }

      .iconfont {
        vertical-align: middle;
        display: inline-block;
        background-color: rgba(255, 255, 255, 0.3);
        font-size: 18px;
        border-radius: 100%;
        width: 36px;
        height: 36px;
        text-align: center;
        line-height: 36px;
        cursor: pointer;
        box-shadow: 0px 0px 5px rgba(0, 0, 0, 0.1);
      }
      .iconfont:hover {
        background-color: rgba(255, 255, 255, 0.5);
      }
      .click-enable {
        right: 0;
      }
    }
  }
}
.loader {
  border: 3px solid rgba(255, 255, 255, 0.1);
  border-radius: 50%;
  border-top: 3px solid #fff;
  width: 14px;
  height: 14px;
  animation: spin 2s linear infinite;
  margin-left: 8px;
}
/* 加载动画关键帧 */
@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}
</style>