import {deleteUserCache, useCache} from '@/hooks/web/useCache'
import { decrypt, encrypt } from '@/utils/jsencrypt'

const { wsCache } = useCache()

const AccessTokenKey = 'ACCESS_TOKEN'
const RefreshTokenKey = 'REFRESH_TOKEN'
const UserNameKey = 'USER_NAME'
const NickNameKey = 'NICK_NAME'

// 获取token
export const getAccessToken = () => {
    // 此处与TokenKey相同，此写法解决初始化时Cookies中不存在TokenKey报错
    return wsCache.get(AccessTokenKey) ? wsCache.get(AccessTokenKey) : wsCache.get('ACCESS_TOKEN')
}

// 刷新token
export const getRefreshToken = () => {
    return wsCache.get(RefreshTokenKey)
}

// 设置token
export const setToken = (token) => {
    //if (token.accessToken !== getAccessToken() ) {
        // TODO 通知子应用刷新token
    //}
    wsCache.set(RefreshTokenKey, token.refreshToken, { exp: token.expiresTime })
    wsCache.set(AccessTokenKey, token.accessToken)
}

// 删除token
export const removeToken = () => {
    wsCache.delete(AccessTokenKey)
    // console.log('after deleting access_token: ', getAccessToken())
    wsCache.delete(RefreshTokenKey)
    // console.log('after deleting refresh_token: ', getRefreshToken())
}

/** 格式化token（jwt格式） */
export const formatToken = (token) => {
    return 'Bearer ' + token
}
// ========== 账号相关 ==========

const LoginFormKey = 'LOGINFORM'

export const getLoginForm = () => {
    const loginForm = wsCache.get(LoginFormKey)
    if (loginForm) {
        loginForm.password = decrypt(loginForm.password)
    }
    return loginForm
}

export const setLoginForm = (loginForm) => {
    loginForm.password = encrypt(loginForm.password)
    wsCache.set(LoginFormKey, loginForm, { exp: 30 * 24 * 60 * 60 })
}

export const removeLoginForm = () => {
    wsCache.delete(LoginFormKey)
}

// ========== 租户相关 ==========

const TenantIdKey = 'TENANT_ID'
const TenantNameKey = 'TENANT_NAME'

export const getTenantName = () => {
    return wsCache.get(TenantNameKey)
}

export const setTenantName = (username) => {
    wsCache.set(TenantNameKey, username, { exp: 30 * 24 * 60 * 60 })
}

export const removeTenantName = () => {
    wsCache.delete(TenantNameKey)
}

export const getTenantId = () => {
    return wsCache.get(TenantIdKey)
}

export const setTenantId = (username) => {
    wsCache.set(TenantIdKey, username)
}

export const removeTenantId = () => {
    wsCache.delete(TenantIdKey)
}

// ========== 用户相关 ==========
export const setUsername = (username) => {
    wsCache.set(UserNameKey, username)
}

export const getUsername = () => {
   return wsCache.get(UserNameKey)
}

export const removeUsername = () => {
    wsCache.delete(UserNameKey)
}

export const setNickname = (nickname) => {
    wsCache.set(NickNameKey, nickname)
}

export const getNickname = () => {
    return wsCache.get(NickNameKey)
}

export const removeNickname = () => {
    wsCache.delete(NickNameKey)
}

/**
 * 用户登出，清除浏览器中的用户相关缓存数据
 */
export function logout() {
    removeTenantId()
    removeToken()
    deleteUserCache()
    removeUsername()
}