export default {
    key: "aa2_bing",
    component: "Aa2Browser",
    icon: "bing.png",
    title: "必应",
    iconColor: "#fff",
    // iconBgColor: "#71e4ee",
    keepInDock: true,
    outLink: true,
    url: process.env.VUE_APP_BROWSER_BASE_URL,
    hideInDesktop: true
}