export default {
    key: "demo_demo",
    component: "Demo",
    icon: "icon-MIS_chanpinshezhi",
    title: "DEMO",
    iconColor: "#fff",
    iconBgColor: "#db5048",
    width: 600,
    height: 400,
    keepInDock: false,
}