export default {
    key: "aa2_chat",
    component: "Aa2Browser",
    icon: "ai_chat.png",
    title: "智 问",
    iconColor: "#fff",
    // iconBgColor: "#71e4ee",
    //width: 1024,
    //height: 900,
    keepInDock: true,
    innerLink: true,
    url: process.env.VUE_APP_CHAT_BASE_URL,
    sendToken: true,
    hideInDesktop: true
}