const config = {
  /**
   * API request base URL
   */
  base_url: process.env.VUE_APP_API_BASE_URL,

  /**
   * Interface successful return status code
   */
  result_code: 200,

  /**
   * Interface request timeout
   */
  request_timeout: 30000,

  /**
   * Default interface request type
   * Optional value: application/x-www-form-urlencoded multipart/form-data
   */
  default_headers: 'application/json'
}

export { config }